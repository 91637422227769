@import '../../styles/mixins';

.home-good-bye-page {
  text-align: center;
  .app {
    text-align: center;
  }

  .shisha-game-logo {
    height: 80px;
  }

  .centered {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .app-header {
    height: 270px;
    padding: 20px;
    color: white;
    overflow: hidden;
    position: relative;
  }

  .app-title {
    font-size: 1.5em;
  }

  .content {
      display: flex;
    justify-content: center;
  }

  .app-intro {
    border-radius: 2rem;
    position: relative;
    margin: 0 1rem;
    padding: 1.5rem;
    max-width: 800px;
    background-color: white;
    color: #273e4c;
    font-size: large;
    top: -10rem;

    h1 {
      margin-top: 1rem;
      color: #d22050;
    }

    h2, h3 {
      margin-top: 3rem;
      color: #d22050;
    }

    .schnaeppo-link {
      img {
        margin: 2rem 0;
        max-width: 18rem;
        border: 1px solid #08a091;
        border-radius: 2rem;
      }
    }
  }

  .app-footer {
    color: #723145;
    background-color: #9d9da9;
    padding: 2rem;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-top: 20px;
  }
  li {
    margin-top: 10px;
  }

  a {
    color: #d22050;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  p.memo {
    width: 500px;
    color: #999;
    font-size: 12px;
    line-height: 150%;
    margin: auto;
  }
}
