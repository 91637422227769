@import '../../styles/mixins';

body {
  background-image: require('../../images/shisha-game-logo.png');
  background-repeat: repeat-x;
  background-color: #818390;
}

.home-app {
  background-repeat: repeat-x;
}
