body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
body {
  background-image: require('../../images/shisha-game-logo.png');
  background-repeat: repeat-x;
  background-color: #818390;
}
.home-app {
  background-repeat: repeat-x;
}
.home-good-bye-page {
  text-align: center;
}
.home-good-bye-page .app {
  text-align: center;
}
.home-good-bye-page .shisha-game-logo {
  height: 80px;
}
.home-good-bye-page .centered {
  display: flex;
  width: 100%;
  justify-content: center;
}
.home-good-bye-page .app-header {
  height: 270px;
  padding: 20px;
  color: white;
  overflow: hidden;
  position: relative;
}
.home-good-bye-page .app-title {
  font-size: 1.5em;
}
.home-good-bye-page .content {
  display: flex;
  justify-content: center;
}
.home-good-bye-page .app-intro {
  border-radius: 2rem;
  position: relative;
  margin: 0 1rem;
  padding: 1.5rem;
  max-width: 800px;
  background-color: white;
  color: #273e4c;
  font-size: large;
  top: -10rem;
}
.home-good-bye-page .app-intro h1 {
  margin-top: 1rem;
  color: #d22050;
}
.home-good-bye-page .app-intro h2,
.home-good-bye-page .app-intro h3 {
  margin-top: 3rem;
  color: #d22050;
}
.home-good-bye-page .app-intro .schnaeppo-link img {
  margin: 2rem 0;
  max-width: 18rem;
  border: 1px solid #08a091;
  border-radius: 2rem;
}
.home-good-bye-page .app-footer {
  color: #723145;
  background-color: #9d9da9;
  padding: 2rem;
}
.home-good-bye-page ul,
.home-good-bye-page li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home-good-bye-page ul {
  margin-top: 20px;
}
.home-good-bye-page li {
  margin-top: 10px;
}
.home-good-bye-page a {
  color: #d22050;
  text-decoration: none;
}
.home-good-bye-page a:hover {
  text-decoration: underline;
}
.home-good-bye-page p.memo {
  width: 500px;
  color: #999;
  font-size: 12px;
  line-height: 150%;
  margin: auto;
}
.home-sad-emoji {
  position: relative;
  text-align: justify;
}
.home-sad-emoji p {
  text-align: Center;
  font-family: 'Fredoka One', cursive;
  color: #3498db;
  font-size: 30px;
  padding: 10px;
}
.home-sad-emoji .face {
  width: 150px;
  height: 150px;
  border: 9px solid #bdc3c7;
  border-radius: 50%;
  -webkit-animation: face 7s infinite;
          animation: face 7s infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  position: relative;
  z-index: 9999;
}
.home-sad-emoji .mouth {
  height: 10px;
  width: 35px;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #bdc3c7;
  -webkit-animation: mouth 7s infinite;
          animation: mouth 7s infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.home-sad-emoji .tear {
  position: absolute;
  background-color: #3498db;
  height: 0px;
  width: 45px;
  top: 62px;
  left: 29px;
  border-bottom-left-radius: 50%;
  -webkit-animation: tear 7s infinite;
          animation: tear 7s infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.home-sad-emoji .tear2 {
  position: absolute;
  background-color: #3498db;
  height: 0px;
  width: 45px;
  top: 62px;
  left: 98px;
  border-bottom-right-radius: 50%;
  -webkit-animation: tear 7s infinite;
          animation: tear 7s infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.home-sad-emoji .eye {
  height: 10px;
  width: 45px;
  background-color: #bdc3c7;
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  -webkit-animation: cry 7s infinite;
          animation: cry 7s infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.home-sad-emoji .eyebrow {
  width: 30px;
  font-size: 40px;
  font-weight: bold;
  color: #bdc3c7;
  display: inline-block;
  margin-left: 27px;
  margin-top: 10px;
  opacity: 0;
  -webkit-animation: eyebrow 7s infinite;
          animation: eyebrow 7s infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.home-sad-emoji .eyebrow:nth-child(1) {
  transform: rotate(-20deg);
}
.home-sad-emoji .eyebrow:nth-child(2) {
  transform: rotate(20deg);
}
@-webkit-keyframes mouth {
  10% {
    height: 30px;
    width: 25px;
    border: 6px solid black;
    border-radius: 50%;
    margin-top: -8px;
  }
  50% {
    height: 30px;
    width: 25px;
    border: 6px solid black;
    border-radius: 50%;
    margin-top: -8px;
  }
  60% {
    height: 10px;
    width: 35px;
    border-radius: 0%;
    border: none;
    margin-top: 15px;
  }
}
@keyframes mouth {
  10% {
    height: 30px;
    width: 25px;
    border: 6px solid black;
    border-radius: 50%;
    margin-top: -8px;
  }
  50% {
    height: 30px;
    width: 25px;
    border: 6px solid black;
    border-radius: 50%;
    margin-top: -8px;
  }
  60% {
    height: 10px;
    width: 35px;
    border-radius: 0%;
    border: none;
    margin-top: 15px;
  }
}
@-webkit-keyframes cry {
  10% {
    transform: translateY(-34px);
    background-color: black;
  }
  50% {
    transform: translateY(-34px);
    background-color: black;
  }
  60% {
    background-color: #bdc3c7;
    transform: translateY(0px);
  }
}
@keyframes cry {
  10% {
    transform: translateY(-34px);
    background-color: black;
  }
  50% {
    transform: translateY(-34px);
    background-color: black;
  }
  60% {
    background-color: #bdc3c7;
    transform: translateY(0px);
  }
}
@-webkit-keyframes eyebrow {
  10% {
    opacity: 1;
    color: black;
  }
  50% {
    opacity: 1;
    color: black;
  }
  60% {
    opacity: 0;
  }
}
@keyframes eyebrow {
  10% {
    opacity: 1;
    color: black;
  }
  50% {
    opacity: 1;
    color: black;
  }
  60% {
    opacity: 0;
  }
}
@-webkit-keyframes face {
  10% {
    border-color: black;
  }
  50% {
    border-color: black;
  }
  60% {
    border-color: #bdc3c7;
  }
}
@keyframes face {
  10% {
    border-color: black;
  }
  50% {
    border-color: black;
  }
  60% {
    border-color: #bdc3c7;
  }
}
@-webkit-keyframes tear {
  10% {
    height: 96px;
  }
  50% {
    height: 96px;
  }
  52% {
    height: 0px;
  }
}
@keyframes tear {
  10% {
    height: 96px;
  }
  50% {
    height: 96px;
  }
  52% {
    height: 0px;
  }
}

